<template>
  <div class="content">
    <!-- 顶部导航 -->
    <div class="contentTop">
      <nav-bar/>
      <div class="gifImg">
        材料云驾驶舱
      </div>
    </div>
    <!-- 内容 -->
    <div class="contentMain">
      <!-- 左半部分 -->
      <div class="left-half">
        <!-- 左上 -->
        <div class="upper-left-half">
          <!-- 上左 用户组织概况 -->
          <div class="leftUpperLeftHalf">
            <div class="title">
              <div>
                <span>用户组织概况</span>
              </div>
            </div>
            <div class="userTotal">
              <div class="userTotalItem">
                <div class="ut-title"><i class="el-icon-s-custom"/>石材云仓</div>
                <div>用户数 <countTo :startVal="0" :endVal="scycList.userTotal" :duration="2000"/></div>
                <div>组织数 <countTo :startVal="0" :endVal="scycList.shopTotal" :duration="2000"/></div>
              </div>
              <div class="userTotalItem">
                <div class="ut-title"><i class="el-icon-s-custom"/>链石大板市场</div>
                <div>用户数 <countTo :startVal="0" :endVal="dbscList.userTotal" :duration="2000"/></div>
                <div>组织数 <countTo :startVal="0" :endVal="dbscList.shopTotal" :duration="2000"/></div>
              </div>
            </div>
            <div class="userCenter">
              <div id="newUser"></div>
              <div id="newOrg"></div>
            </div>
            <div class="boxFoot"></div>
          </div>
          <!-- 上右 地图 -->
          <div class="rightUpperLeftHalf">
            <div id="map"></div>
            <div class="map-title">
              <div class="map-title-item">
                材料商
                <countTo class="ct" :startVal="0" :endVal="mapList.totalShop" :duration="2000" :decimal="indentNum"/><span style="font-size:13px;">家</span>
              </div>
              <div class="map-title-item">
                扫描仪
                <countTo class="ct" :startVal="0" :endVal="mapList.totalScan" :duration="2000" :decimal="indentNum"/>台
              </div>
              <div class="boxFoot"></div>
            </div>
          </div>
        </div>
        <!-- 左下 -->
        <div class="lower-left-half">
          <div class="title">
            <div>
              <span>链石大板市场概况</span>
            </div>
          </div>
          <div class="dataMarketOverview">
            <div class="dataMarketOverviewView">
              <div class="dataLine">
                <div>
                  <countTo :startVal="0" :endVal="marketList.totalOrder" :duration="2000" :decimal="indentNum"/> <br>
                  <div class="dataLineTitle">年度订单（单）</div>
                </div>
                <div>
                  <countTo :startVal="0" :endVal="marketList.totalTurnover" :duration="2000" :decimal="indentNum"/> <br>
                  <div class="dataLineTitle">年度成交金额（￥）</div>
                </div>
                <div>
                  <countTo :startVal="0" :endVal="marketList.totalArea" :duration="2000" :decimal="indentNum"/> <br>
                  <div class="dataLineTitle">年度成交面积（㎡）</div>
                </div>
              </div>
              <div class="select-wrapper">
                <el-select v-model="selectValue" class="select" size="mini" @change="changeSelect()" :popper-append-to-body="false">
                  <el-option
                      v-for="item in selectOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                  />
                </el-select>
              </div>
            </div>
            <div id="orderData"></div>
          </div>
          <div class="dataMarketOverview">
            <div class="dataMarketOverviewView">
              <div class="dataLine">
                <div>
                  <countTo :startVal="0" :endVal="goodsList.totalArea" :duration="2000" :decimal="indentNum"/> <br>
                  <div class="dataLineTitle">在线商品总面积(㎡)</div>
                </div>
                <div>
                  <countTo :startVal="0" :endVal="goodsList.totalScanArea" :duration="2000" :decimal="indentNum"/> <br>
                  <div class="dataLineTitle">在线扫描商品(㎡)</div>
                </div>
                <div>
                  <countTo :startVal="0" :endVal="goodsList.totalNonscanArea" :duration="2000" :decimal="indentNum"/> <br>
                  <div class="dataLineTitle">在线非扫描商品(㎡)</div>
                </div>
              </div>
            </div>
            <div id="goodsData"></div>
          </div>
          <div class="boxFoot"></div>
        </div>
      </div>
      <!-- 右半部分 -->
      <div class="right-half">
        <!-- 右上 石材云仓概况 -->
        <div class="upperRightHalf">
          <div class="title">
            <div>
              <span>石材云仓概况</span>
            </div>
          </div>
          <div class="dataLine">
            <div>
              <countTo :startVal="0" :endVal="stockList.totalInArea" :duration="2000" :decimal="indentNum"/> <br>
              <div class="dataLineTitle">年度入库面积(㎡)</div>
            </div>
            <div>
              <countTo :startVal="0" :endVal="stockList.totalOutArea" :duration="2000" :decimal="indentNum"/> <br>
              <div class="dataLineTitle">年度出库面积(㎡)</div>
            </div>
            <div>
              <countTo :startVal="0" :endVal="stockList.currentArea" :duration="2000" :decimal="indentNum"/> <br>
              <div class="dataLineTitle">当前库存面积(㎡)</div>
            </div>
          </div>
          <div id="stockInOut"></div>
          <div class="boxFoot"></div>
        </div>
        <!-- 右下 链石大板市场概况 -->
        <div class="lowerRightHalf">
          <div class="title">
            <div>
              <span>扫描仪概况</span>
            </div>
          </div>
          <div class="dataLine">
            <div>
              <countTo :startVal="0" :endVal="scanList.totalArea" :duration="2000" :decimal="indentNum"/> <br>
              <div class="dataLineTitle">年度扫描面积(㎡)</div>
            </div>
            <div>
              <countTo :startVal="0" :endVal="scanList.totalScanFee" :duration="2000" :decimal="indentNum"/> <br>
              <div class="dataLineTitle">年度扫描费用(￥)</div>
            </div>
            <div>
              <countTo :startVal="0" :endVal="scanList.totalUnzipFee" :duration="2000" :decimal="indentNum"/> <br>
              <div class="dataLineTitle">年度解压费用(￥)</div>
            </div>
          </div>
          <div id="scanner"></div>
          <div id="scanNum"></div>
          <div id="scanCost"></div>
          <div class="boxFoot"></div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import * as echarts from 'echarts';
import chinaMap from "../assets/map/china.json";
//导入数字滚动
import countTo from "vue-count-to"
import NavBar from './navBar.vue';
export default {
  name: "MarketContent",
  data() {
    return {
      nameSize: 16, //  图表名称字体大小
      exmSize: 10,  //  示例图字体大小
      indentNum: '3', // 缩进位数
      selectValue: 0,   //下拉框选中项
      selectOptions: [
        {
          value: 0,
          label: '成交订单'
        },
        {
          value: 1,
          label: '成交金额'
        },
        {
          value: 2,
          label: '成交面积'
        },
      ],
      markData: [
        {
          title: '订单数走势',
          unit: '(单)',
          desc: '订单数'
        },
        {
          title: '订单金额走势',
          unit: '(元)',
          desc: '成交金额'
        },
        {
          title: '订单面积走势',
          unit: '(㎡)',
          desc: '成交面积'
        },
      ],
      myMap: undefined,
      myOrderDate: undefined,
      myGoodsData: undefined,
      myNewUser: undefined,
      myNewOrg: undefined,
      myStockInOut: undefined,
      myScanner: undefined,
      myScanNum: undefined,
      myScanCost: undefined,
    }
  },
  props: {
    // 上架走势
    goodsList: {
      type: Object,
      default() {
        return {}
      }
    },
    // 市场概况
    marketList: {
      type: Object,
      default() {
        return {}
      }
    },
    // 大板市场用户组织数据
    dbscList: {
      type: Object,
      default() {
        return {}
      }
    },
    // 石材云仓用户组织数据
    scycList: {
      type: Object,
      default() {
        return {}
      }
    },
    // 扫描仪概况
    scanList: {
      type: Object,
      default() {
        return {}
      }
    },
    // 出入库概况
    stockList: {
      type: Object,
      default() {
        return {}
      }
    },
    // 地图数据
    mArr: {
      type: Array,
      default() {
        return []
      }
    },
    // 地图数据总览
    mapList: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  components: {
    countTo,
    NavBar
  },
  created() {
    if(window.screen.width >= 2150) {
      this.nameSize = 18
      this.exmSize = 15
    }
  },
  mounted() {
    this.drawMap()  //
    this.drawOrderData()  // 绘制大板市场订单数据
    this.drawGoodsData() // 绘制大板市场上架走势
    this.drawNewUser() // 绘制用户增长统计
    this.drawNewOrg() // 绘制组织增长统计
    this.drawSotck()
    this.drawScanner()
    this.drawScanNum()
    this.drawScanCost()
  },
  watch: {
    // mArr: {
    //   handler(newValue, oldValue) {
    //     console.log('laile',newValue, oldValue,"111");
    //     // this.drawMap()
    //   },
    //   // deep: true
    // }
  },
  destroyed() {
    this.myMap.dispose()
    this.myOrderDate.dispose()
    this.myGoodsData.dispose()
    this.myNewUser.dispose()
    this.myNewOrg.dispose()
    this.myStockInOut.dispose()
    this.myScanner.dispose()
    this.myScanNum.dispose()
    this.myScanCost.dispose()
  },
  methods: {
    routerIndbsc() {
      this.$router.push('/dbsc')
    },
    routerInlsmc(){
      this.$router.push('/lsmc')
    },
    routerInLsdc() {
      this.$router.push('/lsdc')
    },
    // 选择选项
    changeSelect() {
      this.drawOrderData()
    },
    // 绘制地图
    drawMap() {
      echarts.registerMap('china', {geoJSON:chinaMap})
      let map = document.getElementById('map')
      this.myMap = echarts.init(map)
      let option = {
        tooltip : {
          trigger: 'item',
          backgroundColor:'rgba(50,50,50,0.7)',
          textStyle:{
            color:'rgb(255,255,255)',
            align: 'left',
          },
          formatter: function (params) {
            if(params.data) {
              return params.name+ '<br/>' + params.marker + '材料商：' + params.data.shopNum + '家<br/>' + params.marker+'扫描仪：'+params.data.machineNum + '台';
            }
          },
        },
        // geo: {
        //   map: 'china',
        //   zoom:1.6,
        //   // aspectScale:0.75,
        //   center:[103.5901, 35.3043],
        //   top:'16.5%',
        //   label: {
        //     emphasis: {
        //       show: true
        //     }
        //   },
        //   // roam: true,
        //   itemStyle: {
        //     normal: {
        //       areaColor: '#142957',
        //       borderColor: '#0692a4'
        //     },
        //     emphasis: {
        //       areaColor: '#0b1c2d'
        //     }
        //   },
        //   select:{
        //     itemStyle: {
        //       normal: {
        //         areaColor: '#4c60ff',
        //         borderColor: '#002097'
        //       },
        //       emphasis: {
        //         areaColor: '#293fff'
        //       }
        //     },
        //   }
        // },
        series : [
          {
            name: '中国',
            type: 'map',
            map: 'china',
            zoom:1.6,
            center:[103.5901, 35.3043],
            aspectScale:0.75,
            top:'16.5 %',
            data: this.mArr,
            zlevel:99999,
            roam: true,
            // emphasis:{
            //   itemStyle: {
            //     areaColor: '#293fff'
            //   }
            // },
            label: {
              normal: {
                // formatter: function (params) {
                //   if(params.data) {
                //     return params.name;
                //   }
                // },
                position: 'right',
                show: false,
                // backgroundColor: 'rgba(255, 198, 40, 1)',
              },
              emphasis: {
                show: true,
                textStyle: {
                  color: '#ffffff'
                }
              }
            },
            itemStyle: {
              normal: {
                areaColor: '#142957',
                borderColor: '#0692a4'
              },
              emphasis: {
                areaColor: '#0b1c2d'
              }
            },
            select:{
              itemStyle: {
                normal: {
                  areaColor: '#4c60ff',
                  borderColor: '#002097'
                },
                emphasis: {
                  areaColor: '#0b1c2d'
                }
              },
            }
          }
        ]
      }
      this.myMap.dispatchAction({
        type: 'restore'
      })
      this.myMap.setOption(option)
      const _this = this
      window.addEventListener("resize",function(){
        _this.myMap.resize()
      });
    },
    // 绘制大板市场订单数据
    drawOrderData() {
      let orderData = document.getElementById('orderData')
      this.myOrderDate = echarts.init(orderData)
      let option = {
        title: {
          text: this.markData[this.selectValue].title,
          textStyle: {
            fontSize: this.nameSize,
            color: '#999999'
          }
        },
        tooltip: {
          trigger: 'axis',
          textStyle: {
            align: 'left',
          },
          formatter: function (params) {
            let obj = {
              '订单数': '单',
              '成交金额': '￥',
              '成交面积': '㎡'
            }
            if(params[0].seriesName == '成交金额') {
              if(params[0].data >= 10000) {
                return params[0].name+ '<br/>' + params[0].marker + params[0].seriesName + '：' + obj[params[0].seriesName] + (params[0].data/10000).toFixed(2)+'万';
              } else {
                return params[0].name+ '<br/>' + params[0].marker + params[0].seriesName + '：' + obj[params[0].seriesName] + params[0].data;
              }
            } else {
              return params[0].name+ '<br/>' + params[0].marker + params[0].seriesName + '：' + params[0].data + obj[params[0].seriesName];
            }
          },
        },
        xAxis: {
          type: 'category',
          // data: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
          data: this.marketList.monthList,
        },
        yAxis: {
          type: 'value',
          name:  this.markData[this.selectValue].unit,
          splitLine: {
            lineStyle: {
              type: 'dashed',
            }
          },
          splitNumber : 4,
          axisLabel: {
            formatter: function (value) {
              if(value >= 10000) {
                return (value / 10000).toFixed() + "万";
              } else {
                return value;
              }
            },
          },
        },
        grid: {
          top:"60px",
          left:"70px",
          right:"10px",
          bottom:"20px"
        },
        series: [
          {
            name: this.markData[this.selectValue].desc,
            data: this.marketList.list[this.selectValue],
            type: 'bar',
            barWidth: 18,
            itemStyle: {
              color: '#91cc75'
            },
          }
        ]
      }
      this.myOrderDate.setOption(option)
      const _this = this
      window.addEventListener("resize",function(){
        _this.myOrderDate.resize()
      });
    },
    // 绘制大板市场上架走势
    drawGoodsData() {
      let goodsData = document.getElementById('goodsData')
      this.myGoodsData = echarts.init(goodsData)
      let option = {
        tooltip: {
          formatter: function(params) {
            let relVal = params[0].name
            for (var i = 0, l = params.length; i < l; i++) {
              relVal += '<br/>' + params[i].marker + params[i].seriesName + ': ' + params[i].value + '㎡'
            }
            return relVal
          },
          trigger: 'axis',
          textStyle: {
            align: 'left',
          }
        },
        title: {
          text: '上架走势',
          textStyle: {
            fontSize: this.nameSize,
            color: '#999999'
          }
        },
        grid: {
          top:"60px",
          left:"70px",
          right:"10px",
          bottom:"20px"
        },
        legend: {
          data: ['扫描商品','非扫描商品','商品总数'],
          textStyle: {
            color:'#999',
            fontSize: this.exmSize
          },
          left: 'right',
          itemHeight: 10,
          itemWidth: 10,
          icon: 'roundRect'
        },
        xAxis: {
          type: 'category',
          // data: ['1月','2月','3月','4月','5月','6月','7月','8月','9月','10月','11月','12月'],
          data: this.goodsList.monthList,
        },
        yAxis: {
          type: 'value',
          name: '(㎡)',
          splitNumber: 5,
          splitLine: {
            show: true,
          },
        },
        series: [
          {
            name: '扫描商品',
            type: 'bar',
            data: this.goodsList.scan,
            itemStyle: {
              color: '#5470c6'
            },
          },
          {
            name: '非扫描商品',
            type: 'bar',
            data: this.goodsList.noScan,
            barGap: 0,
            itemStyle: {
              color: '#91cc75'
            },
          },
          {
            name: '商品总数',
            type: 'line',
            // yAxisIndex:1,
            data: this.goodsList.all,
            lineStyle: {
              color: '#fedb5f'
            },
            itemStyle: {
              color: '#fedb5f',
            }
          }
        ]
      }
      this.myGoodsData.setOption(option)
      const _this = this
      window.addEventListener("resize",function(){
        _this.myGoodsData.resize()
      });
    },
    // 绘制用户增长统计
    drawNewUser() {
      let newUser = document.getElementById('newUser')
      this.myNewUser = echarts.init(newUser)
      let option = {
        title: {
          text: '用户增长统计',
          textStyle: {
            fontSize: this.nameSize,
            color: '#999999'
          },
          left: 40,
          top: 45
        },
        legend: {
          orient: 'vertical',
          data: ['石材云仓','大板市场'],
          left: 40,
          top: 85,
          textStyle: {
            color: '#999999',
            fontSize: this.exmSize,
          },
          itemHeight: 10,
          itemWidth: 10,
        },
        tooltip: {
          trigger: 'axis',
          formatter: function(params) {
            let relVal = params[0].name
            for (var i = 0, l = params.length; i < l; i++) {
              relVal += '<br/>' + params[i].marker + params[i].seriesName + ': ' + params[i].value + '人'
            }
            return relVal
          },
          textStyle: {
            align: 'left',
          },
        },
        grid: {
          top:"20%",
          left:"35%",
          right:"5%",
          bottom:"10%"
        },
        xAxis: {
          type: 'category',
          data: this.scycList.monthList
        },
        yAxis: {
          splitLine: {
            show: false,
          },
          splitNumber : 4,
          name: '(人)'
        },
        series: [
          {
            name: '石材云仓',
            type: 'bar',
            data: this.scycList.userList,
            itemStyle: {
              color: '#2cc8f8'
            },
            barWidth: 16,
          },
          {
            name: '大板市场',
            type: 'bar',
            data: this.dbscList.userList,
            itemStyle: {
              color: '#e886a8'
            },
            barGap: 0,
            barWidth: 16,
          }
        ]
      }
      this.myNewUser.setOption(option)
      const _this = this
      window.addEventListener("resize",function(){
        _this.myNewUser.resize()
      });
    },
    // 绘制组织增长统计
    drawNewOrg() {
      let newOrg = document.getElementById('newOrg')
      this.myNewOrg = echarts.init(newOrg)
      let option = {
        title: {
          text: '组织增长统计',
          textStyle: {
            fontSize: this.nameSize,
            color: '#999999'
          },
          left: 40,
          top: 45
        },
        legend: {
          orient: 'vertical',
          data: ['石材云仓','大板市场'],
          left: 40,
          top: 85,
          textStyle: {
            color: '#999999',
            fontSize: this.exmSize,
          },
          itemHeight: 10,
          itemWidth: 10,
        },
        tooltip: {
          trigger: 'axis',
          formatter: function(params) {
            let relVal = params[0].name
            for (var i = 0, l = params.length; i < l; i++) {
              relVal += '<br/>' + params[i].marker + params[i].seriesName + ': ' + params[i].value + '个'
            }
            return relVal
          },
          textStyle: {
            align: 'left',
          }
        },
        grid: {
          top:"20%",
          left:"35%",
          right:"5%",
          bottom:"10%"
        },
        xAxis: {
          type: 'category',
          data: this.scycList.monthList
        },
        yAxis: {
          splitLine: {
            show: false,
          },
          splitNumber : 4,
          name: '(个)'
        },
        series: [
          {
            name: '石材云仓',
            type: 'bar',
            data: this.scycList.shopList,
            itemStyle: {
              color: '#2cc8f8'
            },
            barWidth: 16,
          },
          {
            name: '大板市场',
            type: 'bar',
            data: this.dbscList.shopList,
            itemStyle: {
              color: '#e886a8'
            },
            barGap: 0,
            barWidth: 16,
          }
        ]
      }
      this.myNewOrg.setOption(option)
      const _this = this
      window.addEventListener("resize",function(){
        _this.myNewOrg.resize()
      });
    },
    // 绘制出入库统计
    drawSotck() {
      let stockInOut = document.getElementById('stockInOut')
      this.myStockInOut = echarts.init(stockInOut)
      let option = {
        title: {
          text: '出入库统计',
          textStyle: {
            fontSize: this.nameSize,
            color: '#999999'
          },
          left: 25,
          top: 45
        },
        legend: {
          orient: 'vertical',
          data: ['入库','出库'],
          left: 25,
          top: 85,
          textStyle: {
            color: '#999999',
            fontSize: this.exmSize,
          },
          lineStyle: {
            type: 'solid'
          },
          icon: 'roundRect',
          itemHeight: 10,
          itemWidth: 10,
        },
        tooltip: {
          trigger: 'axis',
          textStyle: {
            align: 'left',
          },
          formatter: function(params) {
            let relVal = params[0].name
            for (var i = 0, l = params.length; i < l; i++) {
              relVal += '<br/>' + params[i].marker + params[i].seriesName + ': ' + params[i].value + '㎡'
            }
            return relVal
          },
        },
        grid: {
          top:"15%",
          left:"30%",
          right:"5%",
          bottom:"15%"
        },
        xAxis: {
          data: this.stockList.monthList,
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#999999'
            }
          },
        },
        yAxis: {
          axisLine: {
            show: true
          },
          axisTick: {
            show: true
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#999999'
            }
          },
          name:'(㎡)',
        },
        series: [
          {
            name: '出库',
            type: 'line',
            data: this.stockList.outList,
            lineStyle: {
              color: '#e886a8'
            },
            itemStyle: {
              color: '#e886a8'
            },
            symbol: 'circle'
          },
          {
            name: '入库',
            type: 'line',
            data: this.stockList.inList,
            lineStyle: {
              color: '#2cc8f8'
            },
            itemStyle: {
              color: '#2cc8f8'
            },
            symbol: 'circle'
          }
        ]
      }
      this.myStockInOut.setOption(option)
      const _this = this
      window.addEventListener("resize",function(){
        _this.myStockInOut.resize()
      });
    },
    // 绘制扫描仪统计
    drawScanner() {
      let scanner = document.getElementById('scanner')
      this.myScanner = echarts.init(scanner)
      let option = {
        title: {
          text: '扫描仪统计',
          textStyle: {
            fontSize: this.nameSize,
            color: '#999999'
          },
          left: 25,
          top: 40
        },
        legend: {
          orient: 'vertical',
          data: ['活跃扫描仪','已安装扫描仪'],
          left: 25,
          top: 80,
          textStyle: {
            color: '#999999',
            fontSize: this.exmSize,
          },
          icon: 'roundRect',
          itemHeight: 10,
          itemWidth: 10,
        },
        grid: {
          top:"17%",
          left:"30%",
          right:"5%",
          bottom:"15%"
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            },
          },
          textStyle: {
            align: 'left',
          },
          formatter: function(pra) {
            return `${pra[0].axisValue}<br/>${pra[1].seriesName}：${pra[1].data}台<br/>${pra[0].seriesName}：${pra[0].data}台<br/>活跃比例：${(pra[0].data/pra[1].data*100).toFixed(2)}%`
          }
        },
        xAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#999999'
            }
          },
          data: this.scanList.monthList
        },
        yAxis: {
          axisLine: {
            show: true
          },
          axisTick: {
            show: true
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#999999'
            }
          },
          splitNumber : 4,
          name:'(台)',
        },
        series: [
          {
            name: '活跃扫描仪',
            type: 'line',
            // stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            lineStyle: {
              color: '#EE6666'
            },
            itemStyle: {
              color: '#EE6666'
            },
            symbol: 'circle',
            zlevel: 3,
            data: this.scanList.activeScanList
          },
          {
            name: '已安装扫描仪',
            type: 'line',
            // stack: 'Total',
            areaStyle: {},
            emphasis: {
              focus: 'series'
            },
            lineStyle: {
              color: '#5470C6'
            },
            itemStyle: {
              color: '#5470C6'
            },
            symbol: 'circle',
            data: this.scanList.allScanList
          }
        ]
      }
      this.myScanner.setOption(option)
      const _this = this
      window.addEventListener("resize",function(){
        _this.myScanner.resize()
      });
    },
    // 绘制扫描量走势
    drawScanNum() {
      let scanNum = document.getElementById('scanNum')
      this.myScanNum = echarts.init(scanNum)
      let option = {
        title: {
          text: '扫描量走势',
          textStyle: {
            fontSize: this.nameSize,
            color: '#999999'
          },
          left: 25,
          top: 70
        },
        grid: {
          top:"17%",
          left:"30%",
          right:"5%",
          bottom:"15%"
        },
        xAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#999999'
            }
          },
          data: this.scanList.monthList
        },
        tooltip: {
          trigger: 'axis',
          textStyle: {
            align: 'left',
          },
          formatter: function(params) {
            let relVal = params[0].name
            for (var i = 0, l = params.length; i < l; i++) {
              relVal += '<br/>' + params[i].marker + params[i].seriesName + ': ' + params[i].value + '㎡'
            }
            return relVal
          },
        },
        yAxis: {
          axisLine: {
            show: true
          },
          axisTick: {
            show: true
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#999999'
            }
          },
          splitNumber : 4,
          name:'(㎡)',
        },
        series: [
          {
            name: '扫描量',
            type: 'line',
            lineStyle: {
              color: '#2cc8f8'
            },
            itemStyle: {
              color: '#2cc8f8'
            },
            data: this.scanList.scanNumList
          },
        ]
      }
      this.myScanNum.setOption(option)
      const _this = this
      window.addEventListener("resize",function(){
        _this.myScanNum.resize()
      });
    },
    // 绘制费用走势
    drawScanCost() {
      let scanCost = document.getElementById('scanCost')
      this.myScanCost = echarts.init(scanCost)
      let option = {
        title: {
          text: '费用走势',
          textStyle: {
            fontSize: this.nameSize,
            color: '#999999'
          },
          left: 25,
          top: 40
        },
        legend: {
          orient: 'vertical',
          data: ['扫描费','解压费'],
          left: 25,
          top: 80,
          textStyle: {
            color: '#999999',
            fontSize: this.exmSize,
          },
          icon: 'roundRect',
          itemHeight: 10,
          itemWidth: 10,
        },
        tooltip: {
          trigger: 'axis',
          textStyle: {
            align: 'left',
          },
          formatter: function(params) {
            let relVal = params[0].name
            for (var i = 0, l = params.length; i < l; i++) {
              relVal += '<br/>' + params[i].marker + params[i].seriesName + ': ' + '￥' + params[i].value
            }
            return relVal
          },
        },
        grid: {
          top:"18%",
          left:"30%",
          right:"5%",
          bottom:"15%"
        },
        xAxis: {
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#999999'
            }
          },
          data: this.scanList.monthList
        },
        yAxis: {
          axisLine: {
            show: true
          },
          axisTick: {
            show: true
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
              color: '#999999'
            }
          },
          splitNumber : 4,
          name:'(￥)',
        },
        series: [
          {
            name: '扫描费',
            type: 'line',lineStyle: {
              color: '#e886a8'
            },
            itemStyle: {
              color: '#e886a8'
            },
            symbol: 'circle',
            data: this.scanList.scanCostList
          },
          {
            name: '解压费',
            type: 'line',
            lineStyle: {
              color: '#2cc8f8'
            },
            itemStyle: {
              color: '#2cc8f8'
            },
            symbol: 'circle',
            data: this.scanList.unzipCostList
          }
        ]
      }
      this.myScanCost.setOption(option)
      const _this = this
      window.addEventListener("resize",function(){
        _this.myScanCost.resize()
      });
    }
  },
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: 100%;
  .contentTop {
    height: 1.5rem;
    width: 100%;
    font-size: .32rem;
    line-height: .32rem;
    color: #ffffff;
    display: flex;
    align-items: center;
    position: relative;
    .gifImg {
      font-size: 0.42rem;
      line-height: 0.42rem;
      width: 100%;
      min-height: .5rem;
      text-align: center;
    }
  }
  .contentMain {
    margin-top: -.2rem;
    font-size: 0.2rem;
    text-align: center;
    color: #ffffff;
    padding: 0 .2rem 0 .2rem;
    display: flex;
    justify-content: space-between;

    .left-half {
      width: 69%;
      height: 100%;

      .upper-left-half {
        width: 100%;
        height: 54%;
        display: flex;
        justify-content: space-between;

        .leftUpperLeftHalf {
          width: 44%;
          height: 4.7rem;
          position: relative;
          // display: flex;
          // flex-direction: column;
          // justify-content: space-between;
          background: rgba(0, 0, 0, 0.3);
          .userTotal {
            width: 100%;
            height: 1rem;
            display: flex;
            justify-content: space-around;
            align-items: flex-end;
            .userTotalItem {
              width: 30%;
              height: 70%;
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              font-size: 13px;
              border-radius: 10px;
              .ut-title {
                font-size: 15px;
                font-weight: bold;
              }
              &:last-child {
                background: #e886a8;
              }
              &:first-child {
                background: #2cc8f8;
              }
              i {
                font-size: 18px;
                margin-right: 5px;
              }
            }
          }
          .userCenter {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }
          #newUser, #newOrg {
            width: 100%;
            height: 1.8rem;
          }
        }
        .rightUpperLeftHalf {
          width: 54%;
          height: 4.7rem;
          position: relative;

          #map {
            width: 100%;
            height: 100%;
          }
          .map-title {
            position: absolute;
            width: fit-content;
            padding: 0 15px;
            top: 0;
            left: 0;
            display: flex;
            background: rgba(0, 0, 0, 0.3);
            font-size: 15px;
            .map-title-item {
              margin: 10px 10px 10px 0;
              &:first-child {
                margin-right: 30px;
              }
              .ct {
                font-size: 20px;
              }
            }
          }
        }
      }
      .lower-left-half {
        width: 100%;
        height: 3.3rem;
        position: relative;
        background: rgba(0, 0, 0, 0.3);
        margin-top: 0.35rem;
        display: flex;
        justify-content: space-between;

        .dataMarketOverview {
          height: 100%;
          width: 48%;
          flex-direction: column;
          // justify-content: space-between;
          padding-top: 0.3rem;
          box-sizing: border-box;

          .dataMarketOverviewView {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            font-size: 14px;
            position: relative;
          }
          #orderData, #goodsData {
            width: 85%;
            height: 2.3rem;
            margin: .2rem auto 0;
          }
        }
      }
    }
    .right-half {
      width: 29%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .upperRightHalf {
        width: 100%;
        height: 2.5rem;
        position: relative;
        background: rgba(0, 0, 0, 0.3);
        .dataLine {
          margin-top: 28px;
        }
        #stockInOut {
          width: 100%;
          height: 1.8rem;
        }
      }
      .lowerRightHalf {
        width: 100%;
        height: 5.5rem;
        position: relative;
        background: rgba(0, 0, 0, 0.3);
        margin-top: 0.35rem;
        .dataLine {
          margin-top: 28px;
        }
        #scanner, #scanNum, #scanCost {
          width: 100%;
          height: 1.6rem;
        }
      }
    }
  }
}
.title{
  position: absolute;
  top: -0.16rem;
  left: 0;
  height: .4rem;
  font-size: .18rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  //border-bottom: 1px solid #1e5b6b;
  div{
    width: 1.65rem;
    height: 0.38rem;
    background: url("../assets/box.png") no-repeat;
    background-size: 100% 100%;
    line-height: 0.38rem;
  }
}
.boxFoot {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
}
.map-title:before,
.leftUpperLeftHalf:before,
.lowerRightHalf:before,
.lower-left-half:before,
.upperRightHalf:before,
.boxFoot:before {
  border-left: 0.02rem solid #02a6b5;
  left: 0;
}
.map-title:after,
.leftUpperLeftHalf:after,
.lowerRightHalf:after,
.upperRightHalf:after,
.lower-left-half:after,
.boxFoot:after {
  border-right: 0.02rem solid #02a6b5;
  right: 0;
}
.map-title:before,
.lowerRightHalf:before,
.map-title:after,
.lowerRightHalf:after,
.lower-left-half:before,
.lower-left-half:after,
.upperRightHalf:before,
.upperRightHalf:after,
.leftUpperLeftHalf:before,
.leftUpperLeftHalf:after {
  border-radius: 0.03rem;
  position: absolute;
  width: .1rem;
  height: .1rem;
  content: '';
  top: 0;
  border-top: 0.02rem solid #02a6b5;
}
.boxFoot:before, .boxFoot:after {
  border-radius: 0.03rem;
  position: absolute;
  width: .1rem;
  height: .1rem;
  content: '';
  bottom: 0;
  border-bottom: 0.02rem solid #02a6b5;
}
.dataLine {
  width: 100%;
  display: flex;
  justify-content: space-around;
  font-size: 20px;
  text-align: center;
  .dataLineTitle {
    font-size: 12px;
  }
}
.select {
  position: absolute;
  right: 0.75rem;
  bottom: -0.5rem;
  width: 1.3rem;
  z-index: 9999999;

  /deep/ .el-select-dropdown__list {
    padding: 0;
  }
  /deep/ .el-input--mini {
    font-size: 13px;
  }
  /deep/ .el-select-dropdown__item.selected {
    color: #fff;
  }
  /deep/ .el-input__inner {
    background: #020314;
    color: #fff;
  }
  /deep/ .el-input__prefix, .el-input__suffix {
    color: #fff;
  }
  /deep/ .el-select-dropdown {
    background-color:#020314;
    color: #fff;
  }
  /deep/ .el-select-dropdown__item.hover, .el-select-dropdown__item:hover {
    background-color: #fff;
    color: #020314;
  }


}
/deep/ .el-select .el-input.is-focus .el-input__inner{
  border-color: #fff !important;
}
/deep/ .el-select .el-input__inner:focus {
  border-color: #fff !important;
}

// @media screen and (min-width: 2150px) {
.content .contentMain {
  height: 90%;
}
.content .contentMain .left-half .upper-left-half .leftUpperLeftHalf {
  height: 6rem;
}
.content .contentMain .left-half .upper-left-half .rightUpperLeftHalf {
  height: 6rem;
}
.content .contentMain .left-half .upper-left-half .leftUpperLeftHalf .userTotal {
  height: 1.4rem;
}
.content .contentMain .left-half .upper-left-half .leftUpperLeftHalf #newUser,
.content .contentMain .left-half .upper-left-half .leftUpperLeftHalf #newOrg {
  height: 2.2rem;
}
.content .contentMain .left-half .lower-left-half {
  height: 4.5rem;
  margin-top: 0.45rem;
}
.content .contentMain .left-half .lower-left-half .dataMarketOverview #orderData,
.content .contentMain .left-half .lower-left-half .dataMarketOverview #goodsData {
  height: 3.3rem;
}

.content .contentMain .right-half .upperRightHalf {
  height: 3.3rem;
}
.content .contentMain .right-half .upperRightHalf #stockInOut {
  height: 2.5rem;
}
.content .contentMain .right-half .lowerRightHalf {
  height: 7.35rem;
}
.content .contentMain .right-half .lowerRightHalf #scanner,
.content .contentMain .right-half .lowerRightHalf #scanNum,
.content .contentMain .right-half .lowerRightHalf #scanCost {
  height: 2.15rem;
}
.title {
  font-size: 0.22rem;
  div {
    width: 2.2rem;
    height: 0.4rem;
  }
}
.content .contentMain .left-half .upper-left-half .leftUpperLeftHalf .userTotal .userTotalItem {
  font-size: 15px;
  .ut-title {
    font-size: 17px;
  }
}
.dataLine {
  font-size: 26px;
  .dataLineTitle {
    font-size: 16px;
  }
}
.content .contentMain .left-half .upper-left-half .rightUpperLeftHalf .map-title {
  font-size: 20px;
  .map-title-item .ct {
    font-size: 26px;
  }
}
.content .contentTop {
  font-size: 0.42rem;
  line-height: 0.42rem;
}
// }
</style>
