<template>
  <div class="index">
    <div id="load">
      <!-- 加载动画 -->
      <div class="load_img" v-if="showLoad">
        <img class="jzxz1" src="../../assets/jzxz1.png">
        <img class="jzxz2" src="../../assets/jzxz2.png">
      </div>
      <!-- 大屏内容 -->
      <div class="content" v-else>
        <market-content
            :goodsList="goodsList"
            :marketList="marketList"
            :dbscList="dbscList"
            :scanList="scanList"
            :stockList="stockList"
            :mArr="mArr1"
            :mapList='mapList'
            :scycList="scycList"
        />
      </div>
    </div>
  </div>
</template>

<script>
import marketContent from '../../components/marketContent.vue'
import Api from '../../api/market'

export default {
  name: 'Dbsc',
  components: {
    marketContent
  },
  data() {
    return {
      xnum: 1,  // 乘系数
      ynum: 3, //  加系数
      timer: null,
      showLoad: true,
      api: new Api(),
      goodsList: {
        scan: [],
        noScan: [],
        all: [],
        monthList: []
      },  // 上架走势
      marketList: {
        totalArea: '',
        totalOrder: '',
        totalTurnover: '',
        list: {
          '0': [],
          '1': [],
          '2': [],
        },
        monthList: []
      },  //  订单数走势
      dbscList: {
        shopTotal: '',
        userTotal: '',
        shopList: [],
        userList: [],
      },  // 大板市场用户组织数据
      scycList: {
        shopTotal: '',
        userTotal: '',
        shopList: [],
        userList: [],
        monthList: []
      },  // 石材云仓用户组织数据
      mapList: {
        totalShop: 0,
        totalScan: 0,
        mapArr: []
      },  // 地图数据
      scanList: {
        totalArea: 0,
        totalScanFee: 0,
        totalUnzipFee: 0,
        activeScanList: [],
        allScanList: [],
        scanNumList: [],
        scanCostList: [],
        unzipCostList: [],
        monthList: []
      }, // 扫描仪概况
      stockList: {
        totalOutArea: 0,
        totalInArea: 0,
        currentArea: 0,
        outList: [],
        inList: [],
        monthList: [],
      },  //出入库
      mArr1: [],  //地图大板市场相关
      mArr2: [],  //地图扫描仪
      reqNum: 0,  //请求完成数
    }
  },
  created() {
    this.getDbscData()
    // this.getAreaMachineData()
    this.getLastMonthStatData()
    this.getLastMonthBoundData()
    this.getLastOrgUserRiseNum()
  },
  beforeDestroy() {
    this.reqNum = 0
    clearInterval(this.timer)
    this.timer = null
  },
  mounted() {
    // this.setTime()
  },
  methods: {
    //设置定时器处理超时
    // setTime() {
    //   const _this = this
    //   setTimeout(function(){
    //     if(_this.reqNum < 5) {

    //     }
    //   },60000);
    // },
    // 判断请求数，进入页面
    goInside() {
      this.reqNum++
      if (this.reqNum >= 5) {
        this.$forceUpdate()
        this.timer = setTimeout(() => {
          this.showLoad = false
          clearTimeout(this.timer)
          this.timer = setTimeout(() => {
            this.$router.push('/lsmc')
            clearTimeout(this.timer)
          }, 15000)
        }, 0)
      }
    },
    goErrorInside() {
      this.reqNum = 0
      location.reload()
      // this.reqNum ++
      // if(this.reqNum >= 5) {
      //   this.$forceUpdate()
      //   this.timer = setTimeout(() => {
      //     clearTimeout(this.timer)
      //     this.timer = setTimeout(() => {
      //       this.$router.push('/lsmc')
      //       clearTimeout(this.timer)
      //     },1100)
      //   },500)
      // }
    },
    // 大板市场数据
    getDbscData() {
      let time = new Date()
      time.setDate(0)
      this.api.getMarketData({
        'endDate': time,
        'monthRange': 12
      }).then(res => {
        let foo = res.result.goods.record
        // 上架走势数据
        this.goodsList.totalArea = res.result.goods.totalArea * this.xnum
        this.goodsList.totalNonscanArea = res.result.goods.totalNonscanArea * this.xnum
        this.goodsList.totalScanArea = res.result.goods.totalScanArea * this.xnum

        for (let i = 0; i < foo.length; i++) {
          this.goodsList.scan.push(foo[i].scanArea * this.xnum)
          this.goodsList.noScan.push(foo[i].nonscanArea * this.xnum)
          this.goodsList.all.push(((foo[i].nonscanArea + foo[i].scanArea) * this.xnum).toFixed(3))
        }

        // 订单数走势数据
        let bar = res.result.order
        this.marketList.totalArea = bar.totalArea * this.xnum
        this.marketList.totalOrder = bar.totalOrder * this.xnum
        this.marketList.totalTurnover = bar.totalTurnover * this.xnum
        let time = []
        bar.record.forEach(val => {
          this.marketList.list[0].push(val.qty * this.xnum)
          this.marketList.list[1].push(val.turnover * this.xnum)
          this.marketList.list[2].push(val.area * this.xnum)
          time.push(val.time)
        });
        time = time.map(item => {
          return (~~item.split('-')[1]) + '月'
        })
        this.goodsList.monthList = time
        this.marketList.monthList = time

        // 大板市场用户组织数据
        this.dbscList.userTotal = res.result.user.total * this.xnum
        this.dbscList.shopTotal = res.result.shop.total * this.xnum
        let dbsc = res.result.shop.record.slice(-6)
        for (let i = 0; i < dbsc.length; i++) {
          this.dbscList.userList.push(res.result.user.record[i].value * this.xnum)
          this.dbscList.shopList.push(dbsc[i].value * this.xnum)
        }

        // 地图数据
        this.mArr1 = []
        res.result.shop.areaCount.map(item => {
          const re = /省|市|直辖/g;  //全局匹配
          this.mArr1.push({
            name: item.provinceName.replace(re, ''),
            selected: false,
            machineNum: 0,
            shopNum: item.total * this.xnum,
            provinceCode: item.provinceCode
          })
          this.mapList.totalShop += parseInt(item.total) * this.xnum
        })
        this.goInside()

        this.getAreaMachineData()
      }).catch(() => {
        this.goErrorInside()
      })
    },
    // 地图数据
    getAreaMachineData() {
      this.api.getAreaMachineData().then(res => {
        let foo = []
        res.Data.map(item => {
          const re = /省|市|直辖/g;  //全局匹配
          foo.push({
            name: item.provinceName.replace(re, ''),
            selected: false,
            machineNum: parseInt(item.machineNum) * this.xnum,
            provinceCode: item.provinceCode
          })
          this.mapList.totalScan += parseInt(item.machineNum) * this.xnum
        })
        let temp = []
        for (var i in foo) {
          let key = foo[i].name; //判断依据
          if (temp[key]) {
            temp[key].name = foo[i].name;
            temp[key].machineNum += foo[i].machineNum;//相加值
          } else {
            temp[key] = {};
            temp[key].name = foo[i].name;
            temp[key].machineNum = foo[i].machineNum;
          }
        }
        this.mArr2 = []
        for (let j in temp) {
          this.mArr2.push(temp[j])
        }

        // 合并地图数据
        for (let i in this.mArr2) {
          for (let j in this.mArr1) {
            if (this.mArr1[j].name == this.mArr2[i].name) {
              this.mArr1[j].machineNum = this.mArr2[i].machineNum
            }
          }
        }

        this.goInside()
      }).catch(() => {
        this.goErrorInside()
      })
    },
    // 扫描仪概况
    getLastMonthStatData() {
      this.api.getLastMonthStatData({
        'monthRange': 12
      }).then(res => {
        res.Data.forEach(item => {
          this.scanList.totalArea += item.scanArea * this.xnum
          this.scanList.totalScanFee += item.scanFee * this.xnum
          this.scanList.totalUnzipFee += item.unzipFee * this.xnum
          this.scanList.activeScanList.push(item.activeNum * this.xnum)
          this.scanList.allScanList.push(item.machineNum * this.xnum)
          this.scanList.scanNumList.push(item.scanArea * this.xnum)
          this.scanList.scanCostList.push(item.scanFee * this.xnum)
          this.scanList.unzipCostList.push(item.unzipFee * this.xnum)
          this.scanList.monthList.push(item.month + '月')
        })
        this.goInside()
      }).catch(() => {
        this.goErrorInside()
      })
    },
    // 石材云仓概况
    getLastMonthBoundData() {
      this.api.getLastMonthBoundData({
        'monthRange': 12
      }).then(res => {
        let regex = /^[0]+/
        this.stockList.totalOutArea = res.Data.totalOutboundArea * this.xnum
        this.stockList.totalInArea = res.Data.totalInboundArea * this.xnum
        this.stockList.currentArea = res.Data.totalArea * this.xnum
        res.Data.list.forEach(item => {
          this.stockList.outList.push(item.outboundArea * this.xnum)
          this.stockList.inList.push(item.inboundArea * this.xnum)
          this.stockList.monthList.push(item.month.replace(regex, "") + '月')
        })
        this.goInside()
      }).catch(() => {
        this.goErrorInside()
      })
    },
    // 石材云仓用户数据
    getLastOrgUserRiseNum() {
      this.api.getLastOrgUserRiseNum({
        monthRange: 6
      }).then(res => {
        this.scycList.shopTotal = res.Data.orgNum * this.xnum
        this.scycList.userTotal = res.Data.userNum * this.xnum
        for (let i in res.Data.list) {
          this.scycList.shopList.push(res.Data.list[i].orgRise * this.xnum)
          this.scycList.userList.push(res.Data.list[i].userRise * this.xnum)
          this.scycList.monthList.push(res.Data.list[i].reportMonth + '月')
        }
        this.goInside()
      }).catch(() => {
        this.goErrorInside()
      })
    }

  }
}
</script>

<style scoped>
/*样式*/
.content {
  width: 100%;
  height: 100%;
}

/* 加载旋转动画 */
#load {
  width: 100%;
  height: 100%;
  position: absolute;
  background: url(../../assets/lsdcBg.png) no-repeat #061537;
  background-size: cover;
  top: 0;
  left: 0;
  z-index: 999
}

#load .load_img {
  position: absolute;
  left: calc(50% - 182px);
  top: calc(50% - 182px);
}

.load_img img {
  position: absolute;
  left: 0;
  top: 0;
}

.load_img .jzxz1 {
  animation: xz1 8s infinite linear;
}

@keyframes xz1 {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.load_img .jzxz2 {
  animation: xz2 7s infinite linear;
}

@keyframes xz2 {
  from {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(-180deg);
  }
  to {
    transform: rotate(-360deg);
  }
}
</style>
