import request from '@/utils/request'
const baseCoreUrl = process.env.VUE_APP_BASE_COREAPI
const baseUrl = process.env.VUE_APP_BASE_API
class Api {
  getMarketData(params) {
    return request({
      url: baseCoreUrl + '/api/reports',
      method: 'GET',
      params
    })
  }
  getAreaMachineData(data) {
    return request({
      url: baseUrl + '/reportapis/report/getAreaMachineData',
      method: 'POST',
      data
    })
  }
  getLastMonthStatData(data) {
    return request({
      url: baseUrl + '/reportapis/report/getLastMonthStatData',
      method: 'POST',
      data
    })
  }
  getLastMonthBoundData(data) {
    return request({
      url: baseUrl + '/reportapis/report/getLastMonthBoundData',
      method: 'POST',
      data
    })
  }
  getLastOrgUserRiseNum(data) {
    return request({
      url: baseUrl + '/reportapis/report/getLastOrgUserRiseNum',
      method: 'POST',
      data
    })
  }

}
export default Api